/*------------------------
    Service Style  
--------------------------*/
.service-wrapper-1 {
    position: relative;
    .shape-image {
        position: absolute;
        z-index: -1;
        &.shape-image-1 {
            top: -28px;
            left: -206px;
        }
        &.shape-image-2 {
            top: -51px;
            right: -257px;
        }
        &.shape-image-3 {
            bottom: 80px;
            right: -37px;
        }
        &.shape-image-4 {
            bottom: -25px;
            left: -201px;
        }
    }
}

.home-one-cat {
    background-image: url(../../images/bg/home-one-service.jpg);
    background-size: cover;
    background-position: center;
}

// .home-one-projects {
//     background-image: url(../../images/bg/home-four-banner-2.jpg);
//     background-size: cover;
//     background-position: center;
// }

.program-service-card {
    background-color: #f7f5ff !important;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}

.service-card-1 {
    background: var(--color-white);
    overflow: hidden;
    text-align: center;
    position: relative;
    transition: 0.4s;
    &::after {
        position: absolute;
        content: "";
        left: 0;
        width: 0;
        height: 2px;
        background: var(--color-primary);
        transition: 0.4s;
        bottom: 0;
    }
    .inner {
        .thumbnail {
            a {
                img {
                    width: 100%;
                }
            }
        }
        .content {
            padding: 30px;

            @media #{$lg-layout} {
                padding: 25px 20px;
            }

            @media #{$md-layout} {
                padding: 25px 20px;
            }

            @media #{$sm-layout} {
                padding: 25px 20px;
            }

            .course-total {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                display: inline-block;
                margin-bottom: 5px;
                color: var(--color-primary);
            }
            .title {
                margin-bottom: 20px;
                
                @media #{$lg-layout} {
                    margin-bottom: 6px;
                }

                @media #{$md-layout} {
                    margin-bottom: 6px;
                }

                @media #{$sm-layout} {
                    margin-bottom: 6px;
                }
            }
            .description {
                margin-bottom: 0;
            }
        }
        .program-info {
            .program-info-content {
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                span {
                    margin-top: 5px;
                }
            }
        }
    }
    &:hover {
        transform: translateY(-15px);
        transition-delay: 0.3s;
        &::after {
            width: 100%;
        }
    }
}

.research-projects {
    height: 100%;
    .inner {
        .thumbnail {
            overflow: hidden;
            img {
                transition-duration: 0.3s;
            }
        }
    }
}

.research-projects:hover {
    img {
        transform: scale(1.2);
    }
}

/*----------------------
    Service Style Two  
-----------------------*/
.service-card-2 {
    padding: 40px 30px;
    background: #EAF8F6;
    border-radius: 5px;
    
    @media #{$lg-layout} {
        padding: 40px 20px;
    }
    
    @media #{$md-layout} {
        padding: 40px 20px;
    }
    
    @media #{$sm-layout} {
        padding: 40px 20px;
    }
    .inner {
        text-align: center;
        .icon {
            position: relative;
            display: inline-block;
            a {
                width: 80px;
                height: 80px;
                background: var(--color-white);
                line-height: 80px;
                text-align: center;
                border-radius: 100%;
                margin: 0 auto;
                display: block;
            }
            img {
                
            }
            .shape-list {
                .shape {
                    position: absolute;
                    z-index: 2;
                    &.shape-1 {
                        bottom: 0;
                        right: 0;
                        transition: 0.4s;
                        opacity: 0;
                    }
                    &.shape-2 {
                        left: 0;
                        top: 50%;
                        transition: 0.4s;
                        opacity: 0;
                    }
                    &.shape-3 {
                        top: 0;
                        right: 0;
                        transition: 0.4s;
                        opacity: 0;
                    }
                }
            }
        }
        .content {
            .title {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 15px;
                margin-top: 25px;
            }
            .description {
                margin-bottom: 0;
            }
        }
    }
    
    &.card-bg-2 {
        background: #FFF3EE;
    }
    &.card-bg-3 {
        background: #EFF4FC;
    }
    &.card-bg-4 {
        background: #FFF1F1;
    }

    &:hover {
        .inner {
            .icon {
                .shape-list {
                    .shape {
                        &.shape-1 {
                            bottom: -8px;
                            right: 3px;
                            opacity: 1;
                        }
                        &.shape-2 {
                            left: -20px;
                            top: 50%;
                            opacity: 1;
                        }
                        &.shape-3 {
                            top: -5px;
                            right: -1px;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.courses-description-content {
    max-width: 900px;
    margin: auto;
    p {
        font-size: 18px;
        margin-bottom: 0;
    }
}

.courses-description-why-join-us-section {
    ol {
        margin: 0 auto;
        li {
            line-height: 1.8;
        }
        // max-width: 700px;
    }
    p {
        max-width: 900px;
        margin: 16px auto;
        font-size: 18px;
    }
}

/* Sponsors */
.sponsors-wrapper {
    .inner {
        text-align: center;

        .icon {
            // background: #FFFFFF;
            // box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.08);
            // border-radius: 10px;
            width: 160px;
            height: 160px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 80px;
            }
        }
    }
}

.sponsors-desktop {
    display: none;
}

.sponsors-tablet {
    display: none;
}

.sponsors-mobile {
    display: block;
}

@media screen and (min-width: 800px) {
    .sponsors-desktop {
        display: none;
    }

    .sponsors-tablet {
        display: block;
    }

    .sponsors-mobile {
        display: none;
    }
}

@media screen and (min-width: 1000px) {
    .sponsors-desktop {
        display: block;
    }

    .sponsors-tablet {
        display: none;
    }

    .sponsors-mobile {
        display: none;
    }
}

.course-description-mobile-content {
    display: flex;
    img {
        border-radius: 12px;
    }
}

.course-description-desktop-content {
    display: none;
}

@media screen and (min-width: 768px) {
    .course-description-mobile-content {
        display: none;
    }
    
    .course-description-desktop-content {
        display: block;
    }
}

/*----------------------
    Service Style Three  
-----------------------*/

.video-section-overlayto-another {
    position: relative;
    margin-bottom: -281px;
    z-index: 1;
}

.service-wrapper-3 {
    position: relative;
    z-index: 1;
    padding-bottom: 281px;
    .about-us-two-service-wrapper {
        background-image: url(../../images/bg/about-us-two-service.jpg);
    }
}


.service-card-3 {
    padding: 30px 30px;
    background: var(--color-white);
    border-radius: 5px;
    transition: 0.4s;
    position: relative;
    z-index: 2;
    .inner {
        text-align: center;
        .icon {
            position: relative;
            display: inline-block;
            margin-bottom: 25px;
            a {
                width: 70px;
                height: 70px;
                background: var(--color-primary);
                text-align: center;
                margin: 0 auto;
                border-radius: 35px 35px 3px 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                i {
                    font-size: 32px;
                    color: var(--color-white);
                }
            }
        }
        .content {
            .title {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 15px;
            }
            .description {
                margin-bottom: 20px;
            }
        }
    }

    &.shape-bg-1 {
        .inner {
            .icon {
                a {
                    background: rgba(113, 82, 233, .15);
                    i {
                        color: var(--color-tertiary-2);
                    }
                }
            }
        }
        &:hover {
            .inner {
                .icon {
                    a {
                        background: var(--color-tertiary-2);
                    }
                }
            }
        }
    }

    &.shape-bg-2 {
        .inner {
            .icon {
                a {
                    background: rgba(255, 164, 27, .15);
                    i {
                        color: var(--color-tertiary);
                    }
                }
            }
        }
        &:hover {
            .inner {
                .icon {
                    a {
                        background: var(--color-tertiary);
                    }
                }
            }
        }
    }

    &.shape-bg-3 {
        .inner {
            .icon {
                a {
                    background: rgba(82, 95, 225, .15);
                    i {
                        color: var(--color-primary);
                    }
                }
            }
        }
        &:hover {
            .inner {
                .icon {
                    a {
                        background: var(--color-primary);
                    }
                }
            }
        }
    }

    &.shape-bg-4 {
        .inner {
            .icon {
                a {
                    background: rgba(248, 111, 3, .15);
                    i {
                        color: var(--color-secondary);
                    }
                }
            }
        }
        &:hover {
            .inner {
                .icon {
                    a {
                        background: var(--color-secondary);
                    }
                }
            }
        }
    }


    &.text-left {
        .inner {
            text-align: left;
        }
    }
    &.bg-grey {
        background: #F5F5F5;
        height: 100%;
        &:hover {
            background: var(--color-white);
            box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.07);
        }
    }
    &:hover {
        transform: translateY(-20px);
        box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.07);
        .inner {
            .icon {
                a {
                    i {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}


/*-------------------------------
    Service Style Four  
---------------------------------*/

.service-card-4 {
    height: 100%;
    .inner {
        background: #F5F5F5;
        border-radius: 5px;
        padding: 40px 35px;
        text-align: center;
        transition: 0.4s;
        height: 100%;

        @media #{$lg-layout} {
            padding: 25px 14px;
        }

        @media #{$md-layout} {
            padding: 25px 14px;
        }

        @media #{$sm-layout} {
            padding: 25px 14px;
        }

        .icon {
            position: relative;
            i {
                display: block;
                color: var(--color-primary);
                font-size: 35px;
            }
            .subtitle {
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: 0.4s;
            }
        }
        .content {
            .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 15px;
                margin-top: 25px;
                a {
                    transition: 0.4s;
                    color: var(--color-heading);
                }
            }
            .description {
                margin-bottom: 0;
                transition: 0.4s;
            }
        }
    }
    &:hover {
        .inner {
            background: var(--color-primary);
            .icon {
                i {
                    opacity: 0;
                }
                .subtitle {
                    opacity: 1;
                    color: var(--color-white);
                }
            }
            .content {
                .title {
                    a {
                        color: var(--color-white);
                    }
                }
                .description {
                    color: var(--color-white);
                }
            }
        }
    }
}


/*-------------------------------
    Service Style Five  
---------------------------------*/
.service-card-5 {
    .inner {
        background: #F5F5F5;
        border-radius: 5px;
        padding: 20px;
        transition: 0.4s;
        display: flex;
        align-items: center;
        .icon {
            position: relative;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            background: #FFFFFF;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.02);
            border-radius: 2px;
            transition: 0.4s;
            justify-content: center;
            margin-right: 20px;
            @media #{$lg-layout} {
                margin-right: 15px;
            }
            i {
                color: var(--color-primary);
                font-size: 30px;
                transition: 0.4s;
            }
        }
        .content {
            .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 0;
                transition: 0.4s;
                @media #{$lg-layout} {
                    font-size: 17px;
                    line-height: 28px;
                }
            }
            .description {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                transition: 0.4s;
            }
        }
    }
    &:hover {
        .inner {
            background: var(--color-primary);
            .icon {
                box-shadow: none;
                background: rgba(255,255,255,0.15);
                i {
                    color: var(--color-white);
                }
            }
            .content {
                .title {
                    a {
                        color: var(--color-white);
                    }
                }
                .description {
                    color: var(--color-white);
                }
            }
        }
    }
}

/*-------------------------------
    Service Style 8  
---------------------------------*/

.service-activation-item5 {
    .single-slick-card {
        margin-bottom: 20px;
    }
}



.service-card-8 {
    .inner {
        background: #EAF8F6;
        border-radius: 5px;
        text-align: center;
        padding: 30px;
        position: relative;
        transition: 0.4s;
        margin-bottom: 20px;
        .icon {
            position: relative;
            width: 75px;
            height: 75px;
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border-radius: 100%;
            justify-content: center;
            margin: 0 auto;
        }

        .content {
            margin-top: 20px;
            .title {
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 5px;
            }
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
            }
        }

        .hover-action {
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            transition: 0.4s;
            opacity: 0;
            .read-more-btn {
                width: 40px;
                height: 40px;
                background: var(--color-primary);
                color: var(--color-white);
                display: inline-block;
                line-height: 43px;
                border-radius: 100%;
                font-size: 20px;
            }
        }
    }

    &.shape-bg-2 {
        .inner {
            background: #FFF3EE;
        }
    }

    &.shape-bg-3 {
        .inner {
            background: #FAEFFA;
        }
    }

    &.shape-bg-4 {
        .inner {
            background: #FFF1F1;
        }
    }
    &.shape-bg-5 {
        .inner {
            background: #EFF4FC;
        }
    }


    &:hover {
        .inner {
            padding-bottom: 50px;
            margin-bottom: -20px;
            .hover-action {
                opacity: 1;
            }
        }
    }
}




/*-------------------------------
    Service Style 7  
---------------------------------*/

.service-card-single {
    position: relative;
    &:first-child {
        &::after {
            background-image: url(../../images/shape/arrow-down.png);
            content: "";
            width: 155px;
            height: 43px;
            right: -19%;
            top: 40px;
            background-size: cover;
            background-position: center center;
            position: absolute;
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
        }
    }
    &:last-child {
        &::after {
            background-image: url(../../images/shape/arrow-top.png);
            content: "";
            width: 155px;
            height: 43px;
            left: -19%;
            top: 0;
            background-size: cover;
            background-position: center center;
            position: absolute;
            @media #{$md-layout} {
                display: none;
            }
            @media #{$sm-layout} {
                display: none;
            }
        }
    }
}
.service-card-7 {
    position: relative;
    .arrow-icon {
        position: absolute;
        left: 100%;
        top: 40px;
        z-index: 1;
    }


    .inner {
       text-align: center;
        .icon {
            position: relative;
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            background: var(--color-secondary);
            border-radius: 100%;
            justify-content: center;
            margin: 0 auto;
            color: var(--color-white);
            margin-bottom: 35px;
            i {
                color: var(--color-white);
                font-size: 32px;
                display: flex;
            }
        }

        .content {
            .title {
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 15px;
            }
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
    &.shape-bg-2 {
        .inner {
            .icon {
                background: var(--color-primary);
            }
        }
    }

    &.shape-bg-3 {
        .inner {
            .icon {
                background: #FFA41B;
            }
        }
    }
}

/* research page */
.research-element {
    min-height: 600px;
}

/* Research Service card */
.research-service-card {
    background-color: #f7f5ff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    text-align: center;
    position: relative;
    transition: 0.4s;
    height: 100%;
    &::after {
        position: absolute;
        content: "";
        left: 0;
        width: 0;
        height: 2px;
        background: var(--color-primary);
        transition: 0.4s;
        bottom: 0;
    }
    .inner {
        height: 100%;
        .thumbnail {
            a {
                img {
                    width: 100%;
                }
            }
        }
        .content-wrapper {
            .content {
                padding: 30px;
    
                @media #{$lg-layout} {
                    padding: 25px 20px;
                }
    
                @media #{$md-layout} {
                    padding: 25px 20px;
                }
    
                @media #{$sm-layout} {
                    padding: 25px 20px;
                }
    
                .course-total {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                    display: inline-block;
                    margin-bottom: 5px;
                    color: var(--color-primary);
                }
                .title {
                    margin-bottom: 20px;
                    
                    @media #{$lg-layout} {
                        margin-bottom: 6px;
                    }
    
                    @media #{$md-layout} {
                        margin-bottom: 6px;
                    }
    
                    @media #{$sm-layout} {
                        margin-bottom: 6px;
                    }
                }
                .description {
                    margin-bottom: 0;
                }
            }
            .program-info {
                .program-info-content {
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                    span {
                        margin-top: 5px;
                    }
                }
            }
        }
        .research-service-card-btn {
            position: relative;
            bottom: 0;
            left: 0;
            padding: 30px;
        }
    }
    &:hover {
        transform: translateY(-15px);
        transition-delay: 0.3s;
        &::after {
            width: 100%;
        }
    }
}

/* Job profiles */
.job-profile-list-mobile {
    display: block;
    .image-wrapper {
        height: 140px;
        padding-left: 0;
    }
}

.job-profile-list-desktop {
    display: none;
    .job-profile-wrapper {
        div:nth-child(2) {
            border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
}

.job-profile-wrapper {
    background-color: white;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    text-align: left;
    p {
        margin-bottom: 8px;
    }
    .image-wrapper {
        img {
            height: 100%;
            max-width: initial;
        }
    }
}

@media screen and (min-width: 769px) {
    .job-profile-list-mobile {
        display: none;
    }

    .job-profile-list-desktop {
        display: block;
    }
}

/* Past Projects by students */
.student-project-carousel-container {
    height: 525px;
}

.past-project {
    background-color: #fff;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    .past-project-title {
        position: fixed;
        bottom: 44px;
        left: 12px;
        color: white;
        background-color: rgba(0, 0, 0, 0.02);
    }
}



/* tabs */
$transition: all 0.6s ease;
.industries-list-tab {
    .tabs {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            background-color: #fff;
            box-shadow: rgba(0,0,0,0.8);
            border-radius: 60px 0 0 60px;
            transition: $transition;
            overflow: hidden;
            z-index: 1;
            cursor: pointer;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                display: inline-block;
                padding: {
                    top: 4px;
                    left: 28px;
                    right: 8px;
                    bottom: 4px;
                }
                h3 {
                    margin: 0;
                }
            }
            &::before {
                content:  "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background-color: var(--color-primary);
                z-index: -1;
                border-radius: 60px 0 0 60px;
                transition: $transition;
            }
            h3 {
                transition: $transition;
                font-size: 20px;
            }
            p {
                margin-bottom: 0;
                display: block;
                transition: $transition;
                // color: $body-color;
            }
            &:active {
                h3 {
                    color: #fff;
                }
                p {
                    color: #fff;
                }
                a {
                    right: 20px;
                }
                &::before {
                    width: 100%;
                    border-radius: 60px 0 0 60px;
                }
            }
            &.current {
                i {
                    color: #fff;
                    transform: rotate(360deg);
                }
                h3 {
                    color: #fff;
                }
                p {
                    color: #fff;
                }
                a {
                    right: 20px;
                }
                &::before {
                    width: 100%;
                    border-radius: 60px 0 0 60px;
                }
            }
        }
    }
}
.industries-img {
    text-align: center;
    img {
        width: auto;
    }
}

.tab {
    .tabs_item {
        display: none;
        &:first-child {
            display: block;
        }
    }
}

.industries-content {
    margin-top: 24px;
    h4 {
        margin-bottom: 10px;
    }
}

.tab-selections {
    height: 350px;
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;               /* width of the entire scrollbar */
    }
      
    &::-webkit-scrollbar-track {
        background: #f2f2f2;
        border-radius: 20px;        /* color of the tracking area */
    }
      
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        // border: 3px solid orange;  /* creates padding around scroll thumb */
    }

    scrollbar-width: 8px;
    scrollbar-color: var(--color-primary) #f2f2f2;
}

.tab-selections-content {
    height: 350px;
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 8px;               /* width of the entire scrollbar */
    }
      
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;        /* color of the tracking area */
    }
      
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary);    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        // border: 3px solid orange;  /* creates padding around scroll thumb */
    }
    scrollbar-width: 8px;
    scrollbar-color: var(--color-primary) transparent;

    .tab_content {
        max-width: 97%;
    }
}

@media screen and (min-width: 769px) {
    .ml-md-20 {
        margin-left: 20px !important;
    }
    .industries-content {
        margin-top: 0;
    }
}

.courses-eligibility-section, .courses-curriculum-section {
    .container {
        max-width: 900px;
        margin: auto;
    }
}

.clean-technology-programs {
    max-width: 1200px;
    margin: auto;
}

.key-features-description, .job-oppotunities-description {
    max-width: 900px;
    margin: auto;
    font-size: 18px;
}

/* Research Project Employers */
.employer-wrapper {
    background-color: transparent;
    padding: 0 8px;
}

.employer-description {
    max-width: 900px;
    margin: auto;
}

.employer-inner {
    background-color: #e5f8ff;
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2);
    height: 100%;
    padding: 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
        margin-bottom: 8px;
    }
    .image-wrapper {
        img {
            // height: 100%;
            max-width: initial;
        }
    }
    &:hover {
        border-radius: 8px;
        .feature-description {
            color: var(--color-black);
        }
    }
}

/* Data Talent */
.data-talent-description {
    max-width: 900px;
    // margin: auto;
    p {
        font-size: 18px;
    }
}

.data-talent-difference {
    padding: 2rem 1rem 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 8px 8px #d3d6fa;
}

.data-talent-eligibility-section {
    .about-feature-list {
        max-width: 900px;
        margin: auto;
    }
}

.data-talent-riipen {
    max-width: 750px;
    margin: auto;
    background-color: rgb(249, 239, 221);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 8px 8px #d3d6fa;
    .data-talent-image-wrapper {
        max-width: 500px;
        margin: auto;
    }
    p {
        margin-bottom: 0;
    }

    .data-talent-cta {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1.5rem;
        flex-wrap: wrap;
    }
}

.skillsboost-description {
    max-width: 900px;
    margin: auto;
    padding: 0 2rem;
}

.skillsboost-cta-note {
    font-size: 18px;
}

.skillsboost-who-should-take-this {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    li {
        font-size: 18px;
    }
}

// Jobs page
.jobs-accordion {
    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        border: none;

        .accordion__item {
            margin-bottom: 15px;
            border: none;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion__button {
            border-radius: 5px;
            display: block;
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
            background: #ffffff;
            padding: 20px 25px;
            color: var(--color-heading);
            text-decoration: none;
            position: relative;
            display: block;
            font-size: 18px;
            font-weight: 600;

            &:focus {
                outline: 0;
            }
            
            i {
                position: absolute;
                right: 25px;
                top: 18px;
                font-size: 25px;
                transition: 0.5s;
            }
        }
        .accordion-title.active {
            i {
                transform: rotate(180deg);
            }
        }
        .accordion__panel {
            background-color: var(--color-white);
            position: relative;
            padding-bottom: 20px;
            padding-right: 60px;
            padding-left: 25px;
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
            p {
                line-height: 1.8;
            }
        }
        .accordion-content.show {
            display: block;
        }
    }
}

.skillsboost_banner_right_illustration {
    width: 80%;
    margin: auto;
}

.datatalent_banner_right_illustration {
    width: 90%;
    margin: auto;
}

// Events page
.event-card {
    max-width: 450px;
    .inner {
        .thumbnail {
            position: relative;
            overflow: hidden;
            .event-card-info {
                height: 350px;
                padding: 1rem;
                background-color: rgba(255, 255, 255, 0.25);
                position: absolute;
                top: 490px;
                backdrop-filter: blur(8px);
                right: 0;
                margin-bottom: 0;
                text-align: left;
                transition-duration: 0.3s;
                transition-delay: 0.3s;
                transition-timing-function: ease-out;
            }
        }
    }
    &:hover {
        .inner .thumbnail {
            .event-card-info {
                background-color: rgba(255, 255, 255, 0.8);
                top: 50%;
                box-shadow: 0 -8px 16px 240px rgba(0, 0, 0, 0.5);
            }
        }
    }
}

.about-m2m {
    font-size: 18px;
    max-width: 860px;
    margin: auto;
}

.our-programs-thumbnail {
    position: relative;
    .our-programs-attribution {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 10px;
        padding: 0 4px;
        background-color: rgba(255, 255, 255, 0.5);
    }
}

// Applied AI solutions page
#FourSight, #PostAI, #RealEstateOutlier, #CourseVibe, #SDGNavigator, #TestGenius, #HealthGuidePlus {
    background-image: url(../../../assets/images/bg/pricing-plan.jpg);
    background-repeat: no-repeat;
    background-attachment: cover;
}

.our-applied-ai-solutions-container {
    max-width: 1440px!important;
}

// Return to work prediction form page
.return-to-work-prediction-section-divider {
    border-bottom: 1px solid black;
}

.client-details-container {
    min-height: 400px;
    align-items: start;
    h6 {
        margin-bottom: 4px;
        margin-top: 0;
        @media #{$custom-lg-device-seven} {
            margin-top: 65px;
        }
    }
    
    .client-detail-wrapper {
        // border-right: 8px solid rgb(210, 210, 210);
        // border-radius: 4px;
        padding-right: 2rem;
    }

    .client-detail-select-container {
        width: 300px;
    }

    .client-detail-container {
        // max-width: 300px;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
    }

    .section-divider-vertical {
        width: 8px;
        height: 100%;
        background-color: rgb(210, 210, 210);
        padding: 0;
        border-radius: 8px;
    }

    .client-predictions-container {
        padding-left: 2rem;

        .recommended-plan-items-container {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
            max-width: 450px;
            @media #{$custom-lg-device-seven} {
                max-width: 100%;
            }
    
            .recommended-plan-item {
                height: auto;
                line-height: 59px;
                
                input {
                    max-width: fit-content;
                }
    
                label {
                    padding-left: 0;
                }
    
                label::before, & label::after {
                    display: none;
                }
            }
        }
    }
}

circle {
    transition: all 0.7s ease-in-out;
}

.plan-items-list {
    height: 500px;
    box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    overflow-y: scroll;
    padding: 0 1rem;
    li {
        list-style-type: none;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        cursor: pointer;
        border: 1px solid #525fe14A;
        background-color: #525fe11A;
        transition: all 0.2s;
        .plan_item-item {
            color: var(--color-primary);
            font-weight: bold;
        }
        .plan_item-reason {
            font-size: 14px;
        }
        &:hover {
            border: 1px solid #f86f0361;
            background-color: #f86f0341;
            box-shadow: 0 0 4px 4px #f86f0361;
        }
    }
}

.caution {
    color: red;
    font-size: 14px;
}

.line-divider {
    border-bottom: 8px solid rgb(210, 210, 210);
    border-radius: 8px;
    margin-top: 40px;
    @media #{$custom-lg-device-seven} {
        margin-top: 120px;
    }
}

.warning_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    z-index: 10;

    .warning_container {
        background-color: #ffffff;
        width: 350px;
        @media #{$md-layout} {
            width:600px;
        }
        @media #{$custom-lg-device-seven} {
            width: 600px;
        }
        // @media #{$xl-layout} {
        //     max-width: 900px;
        // }
        padding: 2rem;
        border-radius: 8px;

        .weight_edit {
            width: 300px;
        }
    }
}

// Healthcare insights UI
.health-care-country-select {
    @media #{$sm-layout} {
        width: 100%;
    }
    @media #{$custom-lg-device-seven} {
        width: 300px;
    }
}

.health-care-province-select {
    @media #{$sm-layout} {
        width: 100%;
    }
    @media #{$custom-lg-device-seven} {
        width: 300px;
    }
}

.chatbot-container {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 8px;
    min-height: 500px;
    padding: 0;
    background-color: #e9e9e9;
    margin-bottom: 4rem;
    overflow: hidden;

    .insights-section {
        background-color: var(--color-shade);
        padding: 1rem 2rem;
        height: 500px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        .insights-section-text {
            padding: 0.5rem 1.5rem;
            background-color: #fff;
            border-radius: 8px;
            margin-top: 1rem;
            font-size: 18px;
            box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.2);
            .insights-section_heading {
                text-decoration: underline;
            }
            .insights-section_subheading {
                list-style-type: none;
                // padding-left: 0;
                h5 {
                    margin-bottom: 0;
                }
                .insights-section_symptoms {
                    list-style-type: none;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 0 1rem;
                    padding-left: 0;
                    li {
                        padding: 0.5rem 1.5rem;
                        background-color: var(--color-tertiary);
                        color: var(--color-white);
                        border-radius: 20px;
                    }
                }
                .insights-section_prevention {
                    list-style-type: none;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 0 1rem;
                    padding-left: 0;
                    li {
                        padding: 0.5rem 1.5rem;
                        background-color: var(--color-success);
                        color: var(--color-white);
                        border-radius: 20px;
                    }
                }
                .insights-section_liklihood {
                    list-style-type: none;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 1rem;
                    padding-left: 0;
                    li {
                        padding: 0.5rem 1.5rem;
                        background-color: var(--color-tertiary-2);
                        color: var(--color-white);
                        border-radius: 20px;
                    }
                }
            }
            &:nth-of-type(n) {
                background-color: var(--color-extra06);
            }
            &:nth-of-type(2n) {
                background-color: var(--color-extra07);
            }
        }
    }

    .chatbot-query-input-wrapper {
        button {
            line-height: 1;
        }
    }

    .chatbot-query-input {
        padding: 1rem 2rem;
    }
}


// About Us Page
.about-us-our-mission-subtext {
    max-width: 600px;
    text-align: center;
    margin: auto;
}

.about-us-banner-point {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    i {
        color: var(--color-secondary);
        font-size: 20px;
        margin-top: -1px;
    }
}

.about-us-our-mission-container {
    .service-card {
        height: 100%;
    }

    p {
        margin-bottom: 0;
        max-width: 900px;
        margin: auto;
    }
}

.our-focus-area-card-odd {
    align-items: center;
    background-image: url(../../images/bg/home-five-banner_v2.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.our-focus-area-card-even {
    align-items: center;
    background-image: url(../../images/bg/home-five-banner-reverse_v2.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.what-we-do-images {
    border-radius: 8px;
}

// Partnerships form
.partnerships-form-frame {
    min-width: 100%;
    max-width: 100%;
    @media #{$sm-layout} {
        height: 1870px;
    }
    height: 1300px;
    border: "none";
    
    .form-all {
        @media #{$sm-layout} {
            margin: 0 auto!important;
        }
    }
}

// AI Resume
.ai-resume-form-frame {
    min-width: 100%;
    max-width: 100%;
    @media #{$sm-layout} {
        height: 710px;
    }
    height: 850px;
    border: "none";
    
    .form-all {
        @media #{$sm-layout} {
            margin: 0 auto!important;
        }
    }
}

// Regulatory Compliance Dashboard
.bar-chart-container {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
    padding: 2rem;
    border-radius: 2rem;
}
.risk-classification-wrapper {
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
        font-family: var(--font-primary);
        font-size: 16px;
        font-weight: 600;
        padding: 0.25rem 1rem;
        border-radius: 2rem;
        width: 8.5rem;
        text-align: center;
        color: white;
    }
    .risk-classification-high {
        background-color: var(--color-danger);
    }
    .risk-classification-medium {
        background-color: var(--color-warning);
    }
    .risk-classification-low {
        background-color: var(--color-success);
    }
    .risk-classification-NA {
        background-color: var(--color-extra01);
    }
}

.p-dropdown-items {
    margin: 0;
    padding: 0;
    li {
        margin: 0;
    }
}

.entity-row-expansion {
    h5 {
        margin-bottom: 0;
    }
    p {
        margin-bottom: 0.5rem;
    }
}

.highlight-table-row {
    &:hover {
        cursor: pointer;
        background-color: #e9e9e9;
    }
}

.filters-container {
    max-width: 600px;
    margin: auto;
    background-color: #efefef;
    padding: 2rem 4rem;
    border-radius: 1.5rem;
}

.entity-filter-label {
    input {
        width: initial;
    }

    label {
        margin-top: 4px!important;
        font-size: 14px!important;
    }
}

.percentage-change {
    background-color: #EAFFF0;
    border-radius: 1.5rem;
    padding: 0.25rem 1.5rem;
    height: 30px;
    img {
        margin-top: -4px;
    }
}

.info-button {
    top: 1.5rem;
    right: 1.5rem;
}

.risk-entities-bar-graph {
    margin-top: 39px;
}

.dashboard-change {
    opacity: 1;
    @media #{$custom-lg-device-seven} {
        opacity: 0;
    }
    transition: all 0.5s;
    color: #7b7b7b!important;
    font-size: 14px;
}

.dashboard-change-card {
    p {
        color: var(--color-black);
    }
    &:hover {
        .dashboard-change {
            opacity: 1;
        }
    }
}

.entities-button-container .distribution-entities {
    text-align: center;
    cursor: pointer;
    border: 2px solid #b7b7b7;
    border-radius: 4px;
    width: 260px;
    padding: 2rem 3rem;
    transition: all 0.2s ease-out;
    margin-bottom: 2rem;
    span {
        font-size: 24px;
        font-weight: 700;
    }
    &:hover {
        background-color: #e7e9fc;
    }
}

.total-entities {
    transition: all 0.2s;
    span:first-of-type {
        font-size: 24px;
        font-weight: 700;
    }
    
    span:nth-of-type(2) {
        cursor: pointer;
        padding: 0 0.75rem;
        background-color: #e7e9fc;
        border: 1px solid var(--color-primary);
        border-radius: 12px;
    }
}

.top-5-high-risk {
    table {
        margin-bottom: 14px;
    }
}

.distribution-entities-active {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border: 2px solid var(--color-primary)!important;
    width: 290px!important;
    background-color: #e7e9fc;
}

.border-8 {
    border: 1px solid #b7b7b7;
    border-radius: 8px;
}

.risk-classification-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;

    .risk-classification-content {
        background-color: var(--color-white);
        padding: 2rem 4rem;
        border-radius: 0.5rem;
        width: 550px;
    }
}

.hidePopup {
    display: none;
}

.feature-importance-section {
    .recharts-responsive-container {
        margin-top: 66px;
    }
    .xAxis .recharts-cartesian-axis-tick text {
        font-size: 8px;
    }

    .recharts-cartesian-axis-tick-value > * {
        transform: rotate(-45deg);
    }
}

.sentiment-analysis-container {
    .recharts-responsive-container {
        margin-top: 30px;
    }
}

.filter-counter {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: var(--color-primary);
    color: white;
    padding: 0.75rem 1rem;
    border-radius: 14px;
    font-size: 12px;
}

// Regulatory compliance dashboard chatbot
.regulatory-chatbot-container {
    cursor: pointer;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
    position: fixed;
    bottom: 8rem;
    right: 2.75rem;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    p {
        font-size: 12px;
        color: white;
        margin-bottom: 0;
        line-height: 1;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

.regulatory-chatbot-box-container {
    background-color: #fff;
    z-index: 10;
    position: fixed;
    bottom: 13.5rem;
    right: 1rem;
    width: calc(100% - 2rem);
    @media #{$custom-lg-device-seven} {
        right: 2.75rem;
        width: 400px;
    }
    border: 2px solid #b7b7b7;
    border-radius: 8px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.5s;
    .regulatory-chatbot-box-wrapper {
        .rcb-header {
            background-color: var(--color-extra06);
            padding: 0.5rem 1rem;
            border-radius: 6px 6px 0 0;
            h5 {
                margin-bottom: 0;
            }
        }
        .rcb-body {
            padding: 2rem 1rem;
            overflow-y: scroll;
            height: 450px;
            transition: all 0.5s;
        }
        .rcb-input {
            border-top: 1px solid #b7b7b7;
            form {
                display: flex;
                flex-direction: row;
                padding: 1rem 1rem 1rem 0;
                input {
                    border: none;
                }
                .rcb-submit {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }
            }
        }
    }
}

.no-height {
    height: 0!important;
}

// Regulatory Chatbotify
.rcb-chat-tooltip {
    z-index: 10;
    position: fixed;
    bottom: 8.9rem!important;
    right: 1rem;
}

.regulatory-chatbotify, .rcb-toggle-button {
    z-index: 10;
    position: fixed;
    bottom: 8.5rem!important;
    // right: 1rem;
}

.rcb-chat-window {
    // position: fixed;
    bottom: 8.5rem!important;
    // right: 1rem!important;
}

/* Toast */
.Toastify {
    z-index: 10;
}

.jobseeker-form-area {
    background-color: #FCF7F3;
}


// Healthcare Chatbot
.chatbox-container {
    border-top: 1px solid var(--color-black);
    border-right: 1px solid var(--color-black);
    border-left: 1px solid var(--color-black);
    border-radius: 8px 8px 0 0;
    max-width: 900px;
    margin: auto;
    padding: 0 2rem 2rem;
    height: 500px;
    display: flex;
    flex-direction: column;
    overFLow-y: scroll;
    
    .bot_wrapper {
        align-self: end;
        margin-top: 1rem;
        max-width: 600px;
        p.bot {
            max-width: max-content;
            margin-bottom: 0;
            border-radius: 1rem 1rem 0 1rem;
            background-color: var(--color-extra06);
            padding: 0.5rem 1rem;
            max-width: max-content;
            line-height: 1.9;
            span {
                border-radius: 1.5rem;
                background-color: var(--color-extra01);
                color: var(--color-white);
                padding: 0.5rem 1.5rem;
                margin-right: 1rem;
                cursor: pointer;
                
                &:hover {
                    background-color: var(--color-extra04);
                }
            }
        }
    }
    
    .input_wrapper {
        align-self: start;
        margin-top: 1rem;
        max-width: 600px;
        p.input {
            margin-bottom: 0;
            border-radius: 1rem 1rem 1rem 0;
            background-color: var(--color-extra07);
            padding: 0.5rem 1rem;
            max-width: max-content;
        }
    }
}

.chatbox-input {
    width: 100%;
    max-width: 900px;
    margin: auto;
    border-right: 1px solid var(--color-black);
    border-bottom: 1px solid var(--color-black);
    border-left: 1px solid var(--color-black);
    border-radius: 0 0 8px 8px;
}

.name-of-sender {
    font-size: 14px;
}


// DataTalent Jobseekers
.data-talent-jobseekers-banner.banner-style-1 {
    padding: 80px 0 60px 0;
    @media #{$custom-lg-device-seven} {
        padding: 80px 0;
    }

    .container {
        @media #{$custom-lg-device-seven} {
            max-width: 1376px;
            padding: 0;
        }

        .row {
            margin-top: 0;
        }
    }

    .banner-left-content {
        margin-top: 16px;
        @media #{$custom-lg-device-seven} {
            margin-top: 60px;
        }

        h1.datatalent-program-title {
            font-size: 40px!important;
            margin-bottom: 0.5rem;
            @media #{$custom-lg-device-seven} {
                font-size: 68px!important;
                margin-bottom: 1.5rem;
            }
        }
        
        p.description {
            background: transparent;
            font-size: 16px;
            padding: 0;
            line-height: 1.6;
            margin-bottom: 1.5rem;
            @media #{$custom-lg-device-seven} {
                font-size: 18px;
            }
            &::before {
                width: 0;
            }
        }
        
        .datatalent-partners-header {
            font-size: 18px;
        }
        
        .datatalent-partners {
            @media #{$custom-lg-device-seven} {
                gap: 3rem;
            }
            a {                
                flex-wrap: nowrap;
                max-width: max-content;
            }
        }
        
        .banner-btns {
            margin-top: 24px;
            @media #{$custom-lg-device-seven} {
                margin-top: 40px;
            }
            gap: 0;
            a {
                font-size: 16px;
                padding: 0;
                width: calc(100% - 0.5rem);
                @media #{$custom-lg-device-seven} {
                    width: calc(100% - 1rem);
                    font-size: 18px;
                }
            }
        }
    }
}


.datatalent-description-span {
    font-size: 12px;
    @media #{$custom-lg-device-seven} {
        font-size: 12px;
    }
}

.data-talent-jobseekers-banner.banner-style-1 .banner-right-content {
    margin-top: 0px;
    padding-left: 16px;
    padding-right: 16px;

    .feature-thumbnail {
        z-index: 0;
        right: 0;
    }
    .video-popup-wrapper.video-popup-two {
        margin-top: 24px;
        margin-bottom: 20px;
        @media #{$custom-lg-device-seven} {
            margin-top: 60px;
        }
        img {
            -webkit-mask-image: url(../../images/bg/black-rect-video.png);
            mask-image: url(../../images/bg/black-rect-video.png);
        }
    }
    .datatalent-stats-container {
        position: relative;
        text-align: center;
        
        .datatalent-stats-courses {
            border-right: 2px solid var(--color-body);
            padding: 0 0.5rem 0 0;
            @media #{$custom-lg-device-seven} {
                padding: 0 1.5rem 0 0;
            }
        }
        .datatalent-stats-ratings {
            padding: 0 0.5rem;
            @media #{$custom-lg-device-seven} {
                padding: 0 1.5rem;
            }
        }
        .datatalent-stats-schedule {
            border-left: 2px solid var(--color-body);
            padding: 0 0 0 0.5rem;
            @media #{$custom-lg-device-seven} {
                padding: 0 0 0 1.5rem;
            }
        }

        .datatalent-stat-header {
            margin-bottom: 0.5rem;
            font-size: 16px;
            @media #{$custom-lg-device-seven} {
                font-size: 24px;
            }
        }
        
        .datatalent-stat-description {
            color: #272836;
            margin-bottom: 0;
            font-size: 10px;
            @media #{$custom-lg-device-seven} {
                font-size: 12px;
            }
        }
    }

    .partners-container {
        .datatalent-partners-header {
            font-size: 16px;
        }
        
        .datatalent-partners {
            gap: 2rem;
            a {                
                flex-wrap: nowrap;
                max-width: max-content;
            }
        }
    }
}

.edu-counterup-and-course-area .container {
    margin-top: 16px;
}

.border-5 {
    border-radius: 5px;
}

.datatalent-section-gap {
    padding: 60px 0;
    @media #{$custom-lg-device-seven} {
        padding: 80px 0;
    }

    .container {
        @media #{$custom-lg-device-seven} {
            max-width: 1376px;
            padding: 0;
        }

        .row {
            margin: 0;
        }
    }
}

.datatalent-section-gap {
    .slick-dots {
        bottom: -40px !important;
    }

    .slick-slider  button.slick-arrow::before {
        color: var(--color-primary);
        font-size: 32px;
    }

    .video-play-btn.with-animation::after, .video-play-btn.with-animation::before {
        border: 1.2px solid #FFA41B!important;
    }
}

.highlight-text-primary {
    color: var(--color-primary);
}

.data-talent-jobseekers-employer_cta {
    background-color: #525FE167;
    .jobseekers-employer_cta {
        margin: 4rem auto 0 auto;
        border-radius: 16px;
    }
}

.ds-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 400px;
    }
}

.datatalent-step-one {
    &::marker {
        font-weight: 700;
        font-size: 20px;
        color: var(--color-heading);
    }
}

.datatalent-step-indent {
    margin-left: 44px;
    line-height: 1.4;
}

p.datatalent-step-indent {
    font-size: 16px;
    @media #{$custom-lg-device-seven} {
        font-size: 18px;
    }
}

.datatalent-steps-footer {
    background-color: #F1E8C3;
    border-radius: 20px;
    padding: 2rem 2rem;
    p {
        color: black;
        margin-bottom: 0;
    }
}

.dsf-num-container {
    background-color: #fff;
    width: 4rem;
    height: 4rem;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dsf-icon-container {
    width: 16px;
    @media #{$custom-lg-device-seven} {
        width: 3rem;
    }
    border-radius: 6px;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    img {
        width: 16px;
        @media #{$custom-lg-device-seven} {
            width: 3rem;
        }
    }
}

.dsf-icon-mobile {
    width: 16px;
}

.dsf-skill {
    font-size: 15px;
}

.datatalent-testimonials {
    font-size: 18px!important;
}

.datatalent-participants-working-at {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    @media #{$custom-lg-device-seven} {
        flex-direction: row;
    }
    align-items: center;
    background-color: #F1E8C3;
    border-radius: 16px;
    padding: 2rem;
}

.dpwa-description {
    color: var(--color-black);
}

.datatalent-jobseeker-bg {
    background-color: #F8F8F8;
}

.dpo-nav {
    border-bottom: 2px solid var(--color-black);
    padding: 1rem 0;
    position: relative;
    @media #{$custom-lg-device-seven} {
        margin-right: 1.5rem;
    }
}

#DPOMobileNavbar, #DPBSMobileNavbar {
    transition: 0.2s all;
    opacity: 0;
    @media #{$custom-lg-device-seven} {
        display: none!important;
    }
}

#DPOMobileNavbar.dpo-nav-sticky, #DPBSMobileNavbar.dpo-nav-sticky {
    background-color: #F8F8F8;
    position: fixed;
    opacity: 1;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 1rem 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.dpo-nav-item {
    font-weight: 700;
    background-color: transparent;
    padding: 8px 12px;
    line-height: 1.5;
    border: 0;
    border-radius: 8px;
    &:hover {
        color: var(--color-primary);
    }
    @media #{$custom-lg-device-seven} {
        padding: 16px 24px;
    }
}

.dpo-nav-item.active-program-overview {
    background: rgba(82, 95, 225, 0.1);
    color: var(--color-primary);
}

.dpo-timeline-heading {
    @media #{$custom-lg-device-seven} {
        font-size: 28px;
    }
}

.dpo-timeline-description {
    margin-bottom: 0;
    @media #{$custom-lg-device-seven} {
        font-size: 18px;
    }
}

.dpo-timeline-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .dpo-timeline-image {
        max-width: 700px;
        width: 100%;
    }
}

.dpo-benefits-partner-logo-container {
    img {
        max-width: 160px;
    }
}

.dpo-benefits-list {
    list-style-image: url(../../../../public/images/icons/list-right-arrow.svg);
    @media #{$custom-lg-device-seven} {
        max-width: 600px;
        margin: auto;
    }

    li {
        padding-left: 1rem;
    }
}

.dpo-aside {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 2rem;
    @media #{$custom-lg-device-seven} {
        padding: 6rem 32px 32px 32px;
    }
    p {
        margin-bottom: 1rem;
    }
}

.dpo-eligibility {
    background-color: #ffffff;
    border: 3px solid var(--color-primary);
    border-radius: 20px;
    // margin: 0;
    padding: 2rem;
    @media #{$custom-lg-device-seven} {
        padding: 3rem;
    }
}

.dpo-eligibility-experience {
    background-color: #EEEFFC;
    border-radius: 15px;
    padding: 1.5rem 1.5rem;
    margin-top: 1rem;
    @media #{$custom-lg-device-seven} {
        padding: 1.5rem 2.5rem;
        margin: 0;
    }
}

.dpo-eligibility-experience-wrapper {
    padding: 0 0 0 2rem;
    p {
        font-size: 14px;
        @media #{$custom-lg-device-seven} {
            font-size: 18px;
        }
    }
}

.eduvibe-testimonial-dataTalent {
    padding: 60px 0;
    @media #{$custom-lg-device-seven} {
        padding: 80px 0;
    }
}

.datatalent-testimonials-container {
    margin-bottom: 0;
}

.datatalent-testimonial-see-more {
    border: none;
    background-color: transparent;
    color: var(--color-primary);
}

ul.slick-dots {
    overflow-x: scroll;
    width: calc(100% - 1.5rem);

    &::-webkit-scrollbar {
        display: none;
    }
      
    & {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.datatalent-showcase-section {
    background-color: #F8F8F8 !important;
    background-image: url(../../images/bg/bg2_mobile.png) !important;
    background-position: bottom right !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    @media #{$custom-lg-device-seven} {
        background-color: #F8F8F8 !important;
        background-image: url(../../images/bg/bg2.png) !important;
        background-position: bottom right !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
    }
}

.datatalent-showcases {
    .service-card-1 {
        &:hover {
            transform: none;
        }
    }

    button.slick-arrow.slick-next {
        z-index: 2;
        right: 25px;
        bottom: -30px;
        top: initial;
    }

    button.slick-arrow.slick-prev {
        z-index: 2;
        left: 13px;
        bottom: -30px;
        top: initial;
    }

    @media #{$custom-lg-device-seven} {    
        button.slick-arrow.slick-next {
            right: 12px;
            top: 50%;
        }
    
        button.slick-arrow.slick-prev {
            left: 0px;
            top: 50%;
        }
    }
}

.datatalent-blog .edu-blog {
    max-width: 550px;
    margin: auto;
}

.datatalent-showcases {
    .video-popup-wrapper img, .edu-blog {
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    }
}

.datatalent-wil-wrapper .datatalent-wil {
    max-width: 563px;
    padding: 2rem 0;
    width: 100%;
    margin: auto;
}

.datatalent-footer {
    background: #E4D186 url(../../images/bg/bg3_mobile.png) no-repeat bottom right!important;
    @media #{$custom-lg-device-seven} {
        background: #E4D186 url(../../images/bg/bg3.png) no-repeat contain bottom right!important;
    }
    padding: 2rem;
    border-radius: 16px;
    @media #{$custom-lg-device-seven} {
        padding: 4rem;
    }
    
    p {
        color: var(--color-black);
    }

    .row .m2m {
        font-size: 8rem;
        bottom: -51px;
        right: -5px;
        color: #E0C654;
        font-weight: 700;
        position: absolute;
        margin: 0;
        line-height: 1;
        max-width: max-content;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @media #{$custom-lg-device-seven} {
            font-size: 18rem;
            bottom: -65px;
            right: -12px;
        }
    }
}

.datatalent-cta {
    position: relative;
    z-index: 1;
    color: var(--color-black)!important;
    background-color: #fff!important;
}


.datatalent-faq-section {
    background-image: url(../../../assets/images/bg/bg1.png);
}

.datatalent-faq-accordion-container {
    max-width: 900px;
    margin: auto;
}


// Annual Membership Page
.am-core-values-container {
    border: 4px solid var(--color-primary);
    border-radius: 16px;
}

.ampo-description {
    max-width: 800px;
    margin: 40px auto;
    p {
        font-size: 24px;
    }
}

.skillsboost-benefits-card-container {
    max-width: 1086px;
    margin: auto;
}

.skillsboost-benefits-card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 24px;
    background: var(--color-white);
    max-width: 326px;
    width: 100%;
    margin: 1.5rem 0;
    @media #{$custom-lg-device-seven} {
        margin: 1.5rem;
    }
}

.skillsboost-benefits-card-header {
    font-size: 18px;
    font-weight: bold;
    color: var(--color-black);
}

//card width: 326px;

.ampo-cta {
    color: var(--color-primary);
    font-style: italic;
    font-weight: bold;
}

.aiready-benefits-container {
    max-width: 1210px;
    margin: auto;
}

.am-packages-see-more {
    color: var(--color-primary);
    font-style: italic;
}

.am-footer {
    background-color: var(--color-primary) !important;
    background-image: url(../../images/bg/ap_mobile_bg.png) !important;
    background-position: bottom right !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    @media #{$custom-lg-device-seven} {
        background-color: var(--color-primary) !important;
        background-image: url(../../images/bg/ap_web_bg.png) !important;
        background-position: bottom right !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
    }
    padding: 2rem;
    border-radius: 16px;
    @media #{$custom-lg-device-seven} {
        padding: 6rem 4rem;
    }
    
    .am-footer-heading {
        color: var(--color-white);
        max-width: 624px;
        font-size: 24px;
        @media #{$custom-lg-device-seven} {
            line-height: 1.3;
            font-size: 44px;
            font-weight: bolder;
        }
    }
    
    .am-cta {
        color: var(--color-black);
        background-color: var(--color-white);
        padding: 2rem 4rem;
        border-radius: 8px;
        font-size: 18px;
        font-weight: bold;
    }
}

.am-sticker {
    position: absolute;
    z-index: 10;
    top: -120px;
    right: 30px;
    @media #{$custom-lg-device-seven} {
        top: -164px;
    }
}


// Business Accelerator
.ba-why-text {
    font-size: 20px;
}

.business-accelerator-banner .inner .content h1.title {
    @media #{$custom-lg-device-seven} {
        font-size: 60px;
    }
}

.business-accelerator-banner {
    min-height: 640px;
    width: 100%;
    padding: 100px 0 80px;
    .banner-left-content {
        margin-top: 0!important;
    }
}

.ba-services {
    padding: 0; 
    max-width: 1100px;
    margin: 0 auto!important;
}

.ba-about-left-card {
    background-color: #FFFAE5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.ba-about-right-card {
    background-color: #FFFAE5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.ba-support {
    background-image: url(../../../../src/assets/images/bg/home-five-testimonial-2.jpg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
}

.ba-support-wrapper {
    border: 4px solid var(--color-primary);
    border-radius: 16px;
    background-color: var(--color-white);
}

.ba-harnessing-ai-card-1 .rounded {
    h5 {
        text-align: center;
        width: calc(100% - 3rem);
        color: white;
        bottom: 15px;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.ba-harnessing-ai-card-2 .rounded {
    h5 {
        text-align: center;
        width: calc(100% - 3rem);
        color: white;
        bottom: 15px;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.ba-harnessing-ai-card-3 .rounded {
    h5 {
        text-align: center;
        width: calc(100% - 3rem);
        color: white;
        bottom: 15px;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.ba-vertical-list-btn {
    line-height: 1.4!important;
    padding: 0.5rem 1rem!important;
    height: auto!important;
    min-height: 60px;
    border: none!important;
    &:focus {
        box-shadow: none!important;
    }
}

.ba-vertical-tab-content {
    @media #{$custom-lg-device-seven} {
        padding: 1rem 2rem;
    } 
}

.ba-description {
    // font-size: 24px;
    // font-weight: 600;
    // max-width: 900px;
    // margin: auto;
}

.ba-subheading {
    font-size: 18px;
    font-weight: 600;
    max-width: 990px;
    margin: auto;
}

.ba-product-info {
    border: 2px solid var(--color-primary);
    border-radius: 4px;
    padding: 2rem;
    max-width: 650px;
    margin: auto;
    p {
        font-size: 18px;
    }
}

.ba-benefits-card {
    width: 240px;
    height: 240px;
    border-radius: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        font-size: 24px;
        font-weight: 600;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 16px;
        text-align: center;
        border-radius: 8px;
        margin-bottom: 0;
    }
}

.ba-benefits-card0 {
    background-image: url(../../../assets/images/business-accelerator/fintech.png);
}

.ba-benefits-card1 {
    background-image: url(../../../assets/images/business-accelerator/ba-benefits_clean_tech.png);
}

.ba-benefits-card2 {
    background-image: url(../../../assets/images/business-accelerator/ba-benefits_iot-5g-and-cybersecurity.png);
}

.ba-benefits-card3 {
    background-image: url(../../../assets/images/business-accelerator/ba-benefits_advanced_manufacturing.png);
}

.ba-benefits-card4 {
    background-image: url(../../../assets/images/business-accelerator/ba-benefits_business_intelligence.png);
}

.ba-benefits-card5 {
    background-image: url(../../../assets/images/business-accelerator/ba-benefits_health_technology.png);
}

.ba-benefits-card6 {
    background-image: url(../../../assets/images/business-accelerator/agtech.png);
}

.ba-comprehensive-description {
    max-width: 980px;
    margin-top: 1rem;
    font-size: 24px;
    font-weight: 600;
}

.ba-application-process-key-points {
    max-width: 528px;
    margin: auto;
}

.ba-application-process-cta {
    max-width: 800px;
    margin: 40px auto 0;
}

.ba-application-process-support {
    border: 4px solid var(--color-primary);
    border-radius: 16px;
}

.ba-perfect-for-card-section {
    max-width: 980px;
    margin: 30px auto 0!important;
    .ba-perfect-for-card {
        border: 1px solid gray;
        border-radius: 16px;
        padding: 16px;
    }
    
    .ba-perfect-for-card-cream {
        background-color: #FFFAE5;    
    }
    
    .ba-perfect-for-card-white {
        background-color: var(--color-white);
    }
}

.ba-footer {
    background-color: var(--color-primary);
    background-image: url(../../images/bg/ap_mobile_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right!important;
    @media #{$custom-lg-device-seven} {
        background-color: var(--color-primary);
        background-image: url(../../images/bg/ap_web_bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom right!important;
    }
    padding: 2rem;
    border-radius: 16px;
    @media #{$custom-lg-device-seven} {
        padding: 4rem;
    }
    
    h5, p, a {
        color: var(--color-white);
    }

    .row .m2m {
        font-size: 8rem;
        bottom: -51px;
        right: -5px;
        // color: #E0C654;
        font-weight: 700;
        position: absolute;
        margin: 0;
        line-height: 1;
        max-width: max-content;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @media #{$custom-lg-device-seven} {
            font-size: 18rem;
            bottom: -65px;
            right: -12px;
        }
    }
    .highlight-text-primary {
        color: var(--color-white);
    }
}

#BAPOMobileNavbar, #BACPOMobileNavbar {
    transition: 0.2s all;
    opacity: 0;
    @media #{$custom-lg-device-seven} {
        display: none!important;
    }
}

#BAPOMobileNavbar.dpo-nav-sticky, #BACPOMobileNavbar.dpo-nav-sticky {
    background-color: #F8F8F8;
    position: fixed;
    opacity: 1;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 1rem 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}


// AI Acceleration
.ai-acceleration-card {
    border-radius: 8px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    // background-color: rgba(82, 95, 225, 0.1);
    background-color: var(--color-white);
    .btn-bg-alt {
        line-height: 50px!important;
        font-size: 16px!important;
    }

    p {
        color: var(--color-black);
    }
    
    .enhancement-number {
        top: -52px;
        left: 16px;
        font-size: 64px;
        font-weight: 700;
        color: var(--color-secondary);
    }
}

.ai-acceleration-card-2 {
    background-color: var(--color-white);
}

.ai-acceleration-footer-note {
    color: var(--color-white);
}

.ai-error {
    color: var(--color-white);
    background-color:var(--color-danger)!important;
    width: max-content;
    margin: 8px auto 0;
    padding: 4px 8px;
    border-radius: 8px;
}

.aia-description {
    max-width: 900px !important;
    margin: 0 auto 40px;
    font-size: 18px;
}

.white-text-black-shadow {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
    backdrop-filter: blur(10px);
    max-width: max-content;
}

/* shimmer effect */

@keyframes light-dark-animation {
    0% {
        background-color: #6F6B8044;
    }
    100% {
        background-color: #6F6B80ff;
    }
}
.aiac-shimmer-heading {
    width: 200px;
    height: 26px;
    border-radius: 16px;
    margin: 32px auto;
    // background-color: var(--color-body);
    animation: light-dark-animation 1s infinite alternate;
}

.aiac-shimmer-body1, .aiac-shimmer-body2 {
    width: 100%;
    height: 18px;
    border-radius: 16px;
    margin: 10px 0;
    // background-color: var(--color-body);
    animation: light-dark-animation 1s infinite alternate;
}

.aiac-shimmer-body2 {
    width: 70%;
}

.aiac-shimmer-cta {
    width: 150px;
    height: 40px;
    border-radius: 8px;
    margin: 40px 0 0 0;
    animation: light-dark-animation 1s infinite alternate;
}


/* Annual Membership Skillsboost Plan */
.membership-description {
    color: #8389CF;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
}

.student-discount {
    font-size: 14px;
    color: var(--color-primary);
}

.ms-glance-card-container {
    // gap: 48px 72px;
    max-width: calc(100% - 40px);
    margin: 4rem auto 0 !important;

    .ms-glance-card {
        background-color: #F3F3F3;
        padding: 16px 32px 32px 32px;
        max-width: 366px;
        margin: auto;
        
        .ms-glance-card-image {
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: start;
        }
    
        .ms-glance-card-description {
            font-size: 22px;
            margin-bottom: 0;
            color: var(--color-black);
        }
    }
}
.ms-offerings-section {
    background-color: #8389CF;
}

.ms-offerings-blockquote {
    color: var(--color-white);
    font-size: 36px;
    max-width: 800px;
    margin: auto;
    text-align: center;
}

.ms-offerings-description {
    color: var(--color-white);
    font-size: 20px;
    max-width: 1000px;
    margin: auto;
}

.ms-about-description {
    font-size: 20px;
    max-width: 900px;
}

.ms-about-description-highlight {
    font-size: 22px;
    max-width: 1000px;
}

.bg-shade {
    background-image: url(../../images/bg/pricing-plan.jpg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.ms-where-to-section {
    background-color: #F3F3F3;
}

.ms-where-to-description {
    font-size: 20px;
    max-width: 900px;
}